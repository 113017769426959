/*global chrome*/
import React, { useEffect } from "react";
import darkLogo from "../../Assets/darkLogo.png";

import "./login.sass";
import { Button } from "../../ui/button";
import { UserIcon, X } from "lucide-react";

const Login = ({ setAuthChanged, authChanged }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setAuthChanged(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeExtension = () => {
    chrome?.tabs?.query({ active: true, currentWindow: true }, function (tabs) {
      chrome?.tabs?.sendMessage(
        tabs[0].id,
        { detail: { key: "showFloatingScreen", value: "false" } },
        function (response) {

        }
      );
    });
    localStorage.setItem("showFloatingScreen", "false");
    const event = new CustomEvent("localStorageChange", {
      detail: { key: "showFloatingScreen", value: "false" },
    });
    window.dispatchEvent(event);
    window?.parent?.postMessage({ type: "extension-closed" }, "*");
  };

  return (
    <>
      <div
        className="bu-cross-icon"
        onClick={() => {
          closeExtension();
        }}
      >
        <X size={24} />
      </div>
      <div className="bu-login-wrap" style={{height: "auto"}}>
        <img className="bu-logo-img" src={darkLogo} alt="Logo" />
        <span className="bu-title" style={{color: "black", marginBottom: "30px", marginTop: "10px"}}>Welcome 🔥</span>
        <span style={{border: "1px solid #E7E7E7", width: "200px"}}></span>
        <span className="bu-sub-title" style={{color: "#4E4E4E", marginTop: "30px", marginBottom: "10px"}}>Sign in to Continue</span>
        <Button
          className="bu-menu-upgrade-btn"
          style={{ color: "#FFFFFF" }}
          onClick={() => {
            window.open("https://app.buildup.school/oauth/", "_blank");
          }}
        >
          Sign In
        </Button>
      </div>
    </>
  );
};

export default Login;
