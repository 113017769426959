import InputBase from '../Common/InputBase';
import './footer.scss';

const Footer = ({
                    sendMessage,
                    setInput,
                    input,
                    sendMessageLoading,
                    setFile, allowSendMessage
                }) => {
    return (
        <InputBase
            disabled={!allowSendMessage}
            input={input}
            sendMessageLoading={sendMessageLoading}
            sendMessage={sendMessage}
            setInput={setInput}
            setFile={setFile}
        />
    )
}

export default Footer
