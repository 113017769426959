import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {useTheme} from "next-themes";

const Layout = ({
                    children,
                    setShowFloatingWindow,
                    setShowMainScreen,
                    sendMessage,
                    setInput,
                    setFile,
                    input,
                    sendMessageLoading,
                    setIsAuthorized,
                    allowSendMessage
                }) => {
    const {theme} = useTheme();

    return (
        <div
            className={`build-up-layout-container`} //${showFloatingWindow ? 'show' : ''}
            style={{height: "auto"}}
        >
            <Header
                setShowFloatingWindow={setShowFloatingWindow}
                setShowMainScreen={setShowMainScreen}
                setIsAuthorized={setIsAuthorized}
            />
            <main className="grow flex flex-col w-full">{children}</main>
            <Footer
                input={input}
                sendMessageLoading={sendMessageLoading}
                sendMessage={sendMessage}
                allowSendMessage={allowSendMessage}
                setInput={setInput}
                setFile={(file) => setFile(file)}
            />
        </div>
    );
};

export default Layout;
